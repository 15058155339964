.odieworks_header
{
  height: 40px;
/*  border-bottom: 3px solid #f0f0f0; */
  border-bottom: 3px solid #e4e4e4; 
}

.header_row
{
  height: 100%;
}


.header_spacer
{
  flex: 0 0 3.333333%;
  max-width: 8.333333%;  
}


.header_button
{
  font-size: 18px;
  font-weight: 400;  
  margin-right: 2px;
}

.header_button_active
{
  color: skyblue;  
}

.header_button:hover
{
  cursor:  pointer;
  color: skyblue;
  border:  2px solid skyblue;  
  margin-left: -2px;
  margin-right: 0px;
}


.header_button_row
{
  height: 100%;
  justify-content: center;
  align-items: center;
/*  margin: 0px;  */
/*  padding:  0px;  */
}


.header_button_col
{
/*  padding: 0px; */
}




/* Desktop and landscape tablets  */
@media (min-width: 768px) 
{


} 


/* Portrait tablets and landscape mobiles */
@media (max-width: 767px) 
{
  .odieworks_header
  {
    height: 60px;
  }

  .header_button
  {
    width:  40%;
    margin-top: 2px;
  }



}

/* Portrait mobiles  */
@media (max-width: 480px) 
{
  .odieworks_header
  {
    height: 60px;
  }

  .header_button
  {
    width:  40%;
    margin-top: 2px;
  }


}
